<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <h2>Action {{ actionObj.ActionFileNumber }}</h2>
      </b-col>
      <b-col v-if="actionObj.ActionTypeName">
        <div class="gray-rectangle">
          {{ actionObj.ActionTypeName }}
        </div>
      </b-col>
      <b-col />
    </b-row>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail/ACTIONS'"
        >
          <h3>Case {{ actionObj.CaseFileNumber }}</h3>
        </router-link>
      </b-col>
      <b-col>
        <h3>Case State: {{ actionObj.CaseStateCode }}</h3>
      </b-col>
      <b-col>
        <h3>Status: In Progress - Investigator</h3>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>E-Mail Case Update</h2>
        <div class="white-bg-container">
          <b-row>
            <b-col md="7">
              <b-form-group
                label="FROM:"
                label-for="from"
                label-cols-sm="3"
              >
                <b-form-input
                  id="from"
                  v-model="From"
                  placeholder=""
                  name="from"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="TO:"
                label-for="to"
                label-cols-sm="3"
              >
                <b-form-input
                  id="to"
                  v-model="To"
                  placeholder=""
                  name="to"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="CC:"
                label-for="cc"
                label-cols-sm="3"
              >
                <b-form-input
                  id="cc"
                  v-model="Cc"
                  placeholder=""
                  name="cc"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="BCC:"
                label-for="bcc"
                label-cols-sm="3"
              >
                <b-form-input
                  id="bcc"
                  v-model="Bcc"
                  placeholder=""
                  name="bcc"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="SUBJECT:"
                label-for="subject"
                label-cols-sm="3"
              >
                <b-form-input
                  id="subject"
                  v-model="Subject"
                  placeholder=""
                  name="subject"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="BODY:"
                label-for="body"
              >
                <div
                  id="body"
                  class="collapse-textarea"
                  :class="{expanded: !isExpanded}"
                >
                  <textarea
                    v-model="Body"
                    class="expandable-textarea"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpanded = !isExpanded">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="sendEmail()"
        >
          Send E-Mail Update
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActivityID:"",

      From: "",
      To: "",
      Cc: "",
      Bcc: "",
      Subject: "",
      Body: "",

      activityObj: {},
      actionObj: {},
      caseObj: {},
      userObj: {},
      JournalTitle: "",

      readOnly: true,
      isExpanded: false,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.caseObj.FileNumber,
        },
      ]
    },
  },
  watch: {},
  async created() {
    try {
      this.getUserInfo();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getActivity() {
      this.ActivityID = this.$route.params.activityID;
      this.loadActivity()
    },
    getUserInfo() {
      apiService
          .get("auth/userinfo")
          .then(res => {
            this.userObj = res.data;
            this.getActivity();
          })
    },
    loadActivity() {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.activityObj = res.data;
            apiService
                .get("action/" + this.activityObj.ActionID + "/detail")
                .then(response => {
                  this.actionObj = response.data;
                  this.getCase();
                })
          })
    },
    getCase() {
      apiService
          .get("case/" + this.actionObj.CaseID + "/detail")
          .then(res => {
            this.caseObj = res.data;
            this.fillData();
          })
    },
    fillData() {
      this.From = this.Cc = this.userObj.FullName + " <" + this.userObj.EMail + ">"
      this.To = this.caseObj.Branch.Requester.Name + ", <" + this.caseObj.Branch.Requester.Email + ">"
      this.Subject = "Case Update - CLAIM " + this.caseObj.ClaimFileNo + " / " + this.caseObj.Branch.Client.Subject.FirstName + ", " + this.caseObj.Branch.Client.Subject.LastName;
      this.Body = this.caseObj.Branch.Requester.Name + "\n\n" +
          this.JournalTitle + "\n\n" + "Thank You!" + "\n" + this.userObj.FullName + "\n" +
          this.isEmpty(this.userObj.Phone) + "\n" + this.isEmpty(this.userObj.Fax) + "\n" + this.isEmpty(this.userObj.MobileSMS) + "\n" +
          " Advantage Surveillance, LLC. \n Unmatched Commitment. Superior Results"
    },
    isEmpty(val) {
      return val ? val : "";
    },
    resetForm() {
      this.$router.push('/cases/edit-action/' + this.activityObj.ActionID);
    },

    objToArr(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },

    sendEmail() {
      const postData = {
        "From": this.From,
        "To": this.To,
        "CC": this.Cc,
        "BCC": this.Bcc,
        "Subject": this.Subject,
        "Body": this.objToArr(encoder.encode(this.Body)),
      }
      apiService.post("journal/entries/sendupdate", postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Email sent!');
          this.$router.push('/cases/edit-action/' + this.activityObj.ActionID);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>